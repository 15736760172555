<template>
  <section>
    <button class="btn-new" v-if="!showWaitInfo" @click="requestStatus()">Request Okta Status</button>
    <p class="taskinfo" v-if="showWaitInfo">
      Request has been sent. It may take a few minutes until it is processed.
      <br><br>
      <span>Estimated waiting time: <b> {{ timer }} seconds</b></span>
    </p>
  </section>

  <div v-if="oktaStatus">
    <p v-if="data" class="taskinfo">Updated: {{ formatDateTime(data.updated) }}<br>This data will expire at {{
      formatDateTime(data.expires) }}</p>

    <ul class="item-list compact">
      <li><b>Status:</b> {{oktaStatus.status}}</li>
      <li><b>Employee number:</b> {{oktaStatus.employeeNumber}}</li>
      <li><b>Name:</b> {{oktaStatus.firstName}} {{oktaStatus.lastName}}</li>
      <li><b>Login:</b> {{oktaStatus.login}}</li>
      <li><b>Employee type:</b> {{oktaStatus.employeeType}}</li>
      <li><b>Created:</b> {{formatDateTime(oktaStatus.created)}}</li>
      <li><b>Activated:</b> {{formatDateTime(oktaStatus.activated)}}</li>
      <li><b>Last login:</b> {{formatDateTime(oktaStatus.lastLogin)}}</li>
      <li><b>Last updated:</b> {{formatDateTime(oktaStatus.lastUpdated)}}</li>
      <li><b>Status changed:</b> {{formatDateTime(oktaStatus.statusChanged)}}</li>
      <li><b>Password changed:</b> {{formatDateTime(oktaStatus.passwordChanged)}}</li>
    </ul>

    <h4>Factors</h4>
    <table class="data-table compact">
      <tr class="data-header">
        <th>FactorType</th>
        <th>Status</th>
        <th>Created</th>
        <th>LastVerified</th>
        <th>AuthenticatorName</th>
      </tr>
      <tr class="data-row" v-for="row in oktaStatus.factors" :key="row.created">
        <td>{{row.factorType}}</td>
        <td>{{row.status}}</td>
        <td>{{formatDateTime(row.created)}}</td>
        <td>{{formatDateTime(row.lastVerified)}}</td>
        <td>{{row.authenticatorName}}</td>
      </tr>
    </table>

    <h4>Event log</h4>
    <table class="data-table compact">
      <tr class="data-header">
        <th>Date</th>
        <th>Type</th>
        <th>Outcome</th>
        <th>Reason</th>
      </tr>
      <tr class="data-row" v-for="row in oktaStatus.authlog" :key="row.published">
        <td>{{formatDateTime(row.published)}}</td>
        <td>{{row.eventType}}</td>
        <td>{{row.outcome.result}}</td>
        <td>{{row.outcome.reason}}</td>
      </tr>
    </table>
    <span class="small">There is a small delay for new events.</span>

    <h4>Application Bindings</h4>
    <table class="data-table compact">
      <tr class="data-header">
        <th>Application</th>
        <th>Username</th>
      </tr>      
      <tr class="data-row" v-for="binding in oktaStatus.appBindings" :key="binding.label">
        <td>{{ binding.label }}</td>
        <td>{{ binding.username }}</td>
      </tr>
    </table>

  </div>
</template>

<script>
import {fetchFromApi, postToApi, formatDateTime} from '@/services/AppTools.js'

export default {
  name: 'OktaStatus',
  data() {
    return {
      data: null,
      oktaStatus : null,
      showWaitInfo: false,
      formatDateTime: formatDateTime,
      timer: "",
      timerInterval: null,
      nextRun: null
    };
  },
  methods: {
    // async loadOktaStatus() {
    //   const userId = this.$route.params.id;

    //   this.$root.loading = true;
    //   this.oktaStatus = await fetchFromApi(this, `/api/user/${userId}/oktastatus`, null, null);
    //   this.$root.loading = false;
    // },
    async requestStatus() {
      const userId = this.$route.params.id;
      await postToApi(this, `/api/user/${userId}/oktastatus/new`);
      await this.pollResult();
    },
    async pollResult() {
      const userId = this.$route.params.id;

      let data = await fetchFromApi(this, `/api/user/${userId}/oktastatus/get`);
      this.data = data;

      // no task running
      if (data === null){
        return
      }

      // task finished
      if (data && data.status == "ok") {
        this.oktaStatus = data["data"];
        this.showWaitInfo = false;
        clearInterval(this.timerInterval);
        return
      }

      // task waiting
      if(!this.showWaitInfo){
        this.showWaitInfo = true;
        const dayjs = require('dayjs'); // import date library,
  
        this.nextRun = dayjs(data.next_run);
        this.timer = Math.round((this.nextRun - Date.now()) / 1000); // in seconds
        this.timerInterval = setInterval(() => {
          this.timer = Math.round((this.nextRun - Date.now()) / 1000); // in seconds
        }, 1000);
      }

      setTimeout(this.pollResult, 3000);
    }
  },
  async beforeMount() {
    await this.pollResult();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.taskinfo {
  font-family: monospace;
  border: 1px solid black;
  border-radius: 3px;
  padding: 8px;
  background-color: #eee;
  font-size: 14px;
}
</style>
